import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Amplify } from 'aws-amplify';
import { environment } from '../environments/environment';
import { AppConstants } from './app.constants';

@Component({
  selector: 'navigatingart-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cat';

  appConstants = AppConstants;

  constructor(private readonly translateService: TranslateService) {
    this.translateService.use(AppConstants.APP_TRANSLATE_DEFAULT_LANG);

    Amplify.configure({
      Storage: {
        S3: {
          bucket: environment.s3?.albumBucketName,
          region: environment.s3?.bucketRegion,
        },
      },
      Auth: {
        Cognito: {
          userPoolId: environment.cognito.userPoolId,
          userPoolClientId: environment.cognito.clientId,
          identityPoolId: environment.s3?.identityPoolId as string,
        },
      },
    });
  }
}
