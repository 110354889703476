import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { NoopTranslateLoader, SSVTranslateLoader } from './shared/ssv-translate-loader';

const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      disableAnimations: (window as any).Cypress ? true : false,
    }),
    MaterialModule,
    AppRoutingModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        useClass: (window as any).Cypress ? NoopTranslateLoader : SSVTranslateLoader,
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: AppRouteReuseStrategy,
    // },
    environment.sentry?.enabled ? sentryProviders : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
