import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* istanbul ignore next */
const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
  { path: '', loadChildren: () => import('./authenticated/authenticated.module').then((m) => m.AuthenticatedModule) },
  { path: '**', redirectTo: '/auth' },
].filter((o) => !!o); // Remove disabled elements

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
