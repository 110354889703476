import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentry?.enabled) {
  Sentry.init({
    ...environment.sentry,
    autoSessionTracking: true,
    tracesSampleRate: 0.2,
    beforeSend(event, hint) {
      const error = hint?.originalException as Error;
      if (error && error.message.match(/40[349]/)) {
        return null;
      }
      return event;
    },
    integrations: [Sentry.browserTracingIntegration()],
  });
}

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

persistState({
  key: 'na-app',
  include: [
    'auth.session',
    'DataTable-Archive-List.ui',
    'DataTable-Artworks-List.ui',
    'DataTable-Publication-List.ui',
    'DataTable-Agents-List.ui',
    'DataTable-Auctions-List.ui',
    'DataTable-Exhibitions-List.ui',
    'DataTable-Resource-Select-List.ui',
    'DataTable-Indexing-Terms-List.ui',
    'DataTable-Committee-List.ui',
    'DataTable-Global-Texts-List.ui',
    'DataTable-Essays-List.ui',
    'DataTable-Artwork-Sets-List.ui',
    'DataTable-Geographic-Location-List.ui',
    'entity-reports',
    'DataTable-Storage-Location-List.ui',
    'DataTable-Condition-Assessment-List.ui',
    'DataTable-Artworks-Changelog-List.ui',
    'DataTable-Markings-List.ui',
    'DataTable-TaskManager-Task-List.ui',
    'DataTable-TaskManager-Entity-Task-List.ui',
    'DataTable-Global-Notes-List.ui',
  ],
  // preStorageUpdateOperator: () => (window as any).Cypress ? debounceTime(0) : debounceTime(2000),
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
